import React from 'react';
import PreviewCompatibleImage from '../PreviewCompatibleImage';

export const SponsorItem = ({ image }) => {
  return (
    <div className="flex flex-col items-center justify-center w-64 h-64 p-8 mx-4 my-4 transition duration-500 ease-in-out transform rounded-md shadow-lg cursor-pointer select-none hover:scale-110">
      <PreviewCompatibleImage
        className="w-full h-full"
        imgStyle={{ objectFit: 'contain' }}
        image={image}
        alt="sponsor"
      />
    </div>
  );
};

export default SponsorItem;
