import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Header from '../components/Header';
import ProgramProvidersContent from '../components/About/ProgramProvidersContent';

export const ProgramProvidersPageTemplate = ({
  title,
  cta,
  info,
  benefits,
  partners,
  providerLinks
}) => {
  return (
    <div className="w-full flex flex-col bg-s4tk-white">
      <Header title={title} />
      <ProgramProvidersContent
        cta={cta}
        info={info}
        benefits={benefits}
        partners={partners}
        links={providerLinks}
      />
    </div>
  );
};

const ProgramProvidersPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <ProgramProvidersPageTemplate
        title={frontmatter.title}
        cta={frontmatter.cta}
        info={frontmatter.info}
        benefits={frontmatter.benefits}
        partners={frontmatter.partners}
        providerLinks={frontmatter.providerLinks}
      />
    </Layout>
  );
};

export default ProgramProvidersPage;

export const programProvidersPageQuery = graphql`
  query ProgramProvidersPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "program-providers" } }) {
      frontmatter {
        title
        cta {
          heading
          blurbs {
            text
          }
          item {
            title
            icon {
              prefix
              title
            }
            url
          }
        }
        info {
          heading
          blurbs {
            text
          }
        }
        benefits {
          heading
          items {
            text
          }
        }
        partners {
          items {
            image {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 50) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            link
          }
        }
        providerLinks {
          items {
            title
            icon {
              prefix
              title
            }
            url
          }
        }
      }
    }
  }
`;
