import React from 'react';
import Section, { SubSection } from '../Section';
import SmallLink from '../SmallLink';
import SponsorItem from './SponsorItem';

export const ProgramProvidersContent = ({
  cta,
  info,
  benefits,
  partners,
  links
}) => {
  return (
    <Section>
      <SubSection>
        <p className="font-baloo text-s4tk-blue text-3xl font-bold mb-4">
          {cta.heading}
        </p>
        <div className="space-y-4 w-full self-center px-0 md:px-4 lg:px-8 mb-16">
          {cta.blurbs.map((blurb, index) => (
            <p
              key={index}
              className="font-baloo text-black text-xl text-left sm:text-justify"
            >
              {blurb.text}
            </p>
          ))}
        </div>
        <div className="w-full flex items-center justify-center">
          <SmallLink item={cta.item} />
        </div>
      </SubSection>
      <SubSection>
        <p className="font-baloo text-s4tk-blue text-3xl font-bold mb-4">
          {info.heading}
        </p>
        <div className="space-y-4 w-full self-center px-0 md:px-4 lg:px-8">
          {info.blurbs.map((blurb, index) => (
            <p
              key={index}
              className="font-baloo text-black text-xl text-left sm:text-justify"
            >
              {blurb.text}
            </p>
          ))}
        </div>
      </SubSection>
      <SubSection>
        <p className="font-baloo text-s4tk-blue text-3xl font-bold mb-2">
          {benefits.heading}
        </p>
        <ul className="list-disc list-inside px-0 md:px-4 lg:px-8 space-y-2 mb-4">
          {benefits.items.map((item, index) => (
            <li
              key={index}
              className="font-baloo text-black text-xl text-left sm:text-justify"
            >
              {item.text}
            </li>
          ))}
        </ul>
      </SubSection>
      <SubSection>
        <div className="w-full flex flex-row flex-wrap justify-around items-center">
          {partners.items.map((item, index) => (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={item.link ?? '/'}
            >
              <SponsorItem key={index} image={item.image} />
            </a>
          ))}
        </div>
      </SubSection>
      <div className="w-full py-16 flex flex-row flex-wrap justify-around items-center">
        {links.items.map((item, index) => (
          <SmallLink internal={item.url[0] === '/'} key={index} item={item} />
        ))}
      </div>
    </Section>
  );
};

export default ProgramProvidersContent;
